<template>
    <v-container>
        <v-skeleton-loader v-if="api.isLoading" type="table" ref="skeleton"></v-skeleton-loader>
        <v-row>
            <v-col>

                <v-data-table
                :search="this.searchData"
                :items="this.filteredData"
                :headers="this.dataHeader"
                class="elevation-1"
                dense v-if="this.data!=null && !api.isLoading">
    
                    <template v-slot:item.created_at="{item}">
                        <span>
                            {{ convertTimeZone(item.created_at) }}
                        </span>
                    </template>
                    <template v-slot:item.invoice_expired_at="{item}">
                        <span>
                            {{ convertTimeZone(item.invoice_expired_at) }}
                        </span>
                    </template>
                    <template v-slot:item.invoice_created_at="{item}">
                        <span>
                            {{ convertTimeZone(item.invoice_created_at) }}
                        </span>
                    </template>
                    <template v-slot:item.Index="{item}">
                        {{ data.indexOf(item) +1 }}
                    </template>
                    <template v-slot:item.deleted_at="{item}">
                        <span v-if="item.deleted_at!=null">Inactive</span>
                        <span v-else>
                            Active
                        </span>
                    </template> 
                    <template v-slot:item.company_name="{item}">
                        <router-link :to="{name:'PageCompaniesDetail',params:{id:item.company_id}}">
                            {{ item.company_name }}
                        </router-link>
                    </template>


                    <template v-slot:top>
                             <v-toolbar height="auto" width="auto" class="py-3" flat>
                                <v-row no-gutters>
                                    <v-col class="pa-0 ma-0">
                                        <v-row>
                                            <v-toolbar-title class="font-weight-bold text-h4 mt-3 mb-6 ml-5">
                                                PIC
                                            </v-toolbar-title>
    
                                        </v-row>

                                        <div class="d-flex justify-start"> 
                                            <span class="text-h7 my-3 grey--text">
                                                Assigned Date : 
                                            </span>
                                        </div>
                                        <v-row class="">
                                            <v-col class="pr-0">
                                                <v-text-field style="border-radius:4px 0px 0px 4px" dense outlined clearable
                                                prepend-inner-icon="mdi-calendar"
                                                @click:prepend-inner="()=>{
                                                    isStartDate = true;
                                                }"
                                                @click="()=>{
                                                    isStartDate =true;
                                                }"
                                                label="Start Date..."
                                                v-model = "startDate">
                    
                                                </v-text-field>
                    
                                                <v-dialog v-model="isStartDate" height="auto" width="auto">
                                                    <v-card class="pa-3">
                                                        <div class="d-flex flex-column">
                                                            <p>
                                                                Start Date
                                                            </p>
                                                            <div>
    
                                                                <v-date-picker flat v-model="startDate" :landscape="true"
                                                                :reactive="true">
                                                                </v-date-picker>
    
                                                                <slot name="footer">
                                                                    <div class="d-flex justify-end white" >
                                                                        <v-btn color="primary" plain @click="()=>{
                                                                            isStartDate=false;
                                                                            startDate=null;
                                                                        }">
                                                                            Cancel
    
                                                                        </v-btn>
                                                                        <v-btn color="primary" @click="()=>{
                                                                            isStartDate = false;
                                                                        }">
                                                                            Confirm
                                                                        </v-btn>
                                                                    </div>
                                                                </slot>
                                                            </div>
                                                        </div>
                                                    </v-card>
                                                </v-dialog>
                                            </v-col>
                                            <v-col class="pl-0">
                                                <v-text-field dense outlined clearable style="border-radius:0px 4px 4px 0px"
                                                prepend-inner-icon="mdi-calendar"
                                                @click:prepend-inner="()=>{
                                                    isEndDate = true;
                                                }"
                                                @click="()=>{
                                                isEndDate =true;
                                                }"
                                                label="End Date..."
                                                v-model = "endDate">
                    
                                                </v-text-field>
                                                
                                                <v-dialog v-model="isEndDate" height="auto" width="auto">
                                                    <v-card class="pa-3">
                                                    <div class="d-flex flex-column">
                                                        <p>
                                                            End Date
                                                        </p>
                                                        <div>
                                                            <v-date-picker flat v-model="endDate" :landscape="true"
                                                            :reactive="true">
                                                            </v-date-picker>
                                                        </div>
                                                    </div>
                                                    <div name="footer">
                                                        <div class="d-flex justify-end my-3">
                                                            <v-btn color="primary" plain @click="()=>{
                                                                isEndDate = false;
                                                                endDate=null;
                                                            }">
                                                                Cancel
                                                            </v-btn>
                                                            <v-btn color="primary" @click="()=>{
                                                                isEndDate = false;
                                                            }">
                                                                Confirm
                                                            </v-btn>
                                                        </div>
                                                    </div>
                                                </v-card>
                                                </v-dialog>
                                            </v-col>
                                        </v-row>
                                        
                                        <v-row>
                                            <v-col cols="12" sm="6">
                                                <div class="d-flex justify-start mx-1">
                                                    <span class="text-h7 grey--text mb-3">
                                                        Subscription Date :
                                                    </span>
                                                </div>

                                                <v-row class="ml-1">
                                                    <v-col class="px-0">
                                                        <v-text-field style="border-radius:4px 0px 0px 4px" dense outlined clearable
                                                        prepend-inner-icon="mdi-calendar"
                                                        @click:prepend-inner="()=>{
                                                            isStartDateSubscription = true;
                                                        }"
                                                        @click="()=>{
                                                            isStartDateSubscription =true;
                                                        }"
                                                        label="Start Date..."
                                                        v-model = "startDateSubscription">
                            
                                                        </v-text-field>
                            
                                                        <v-dialog v-model="isStartDateSubscription" height="auto" width="auto">
                                                            <v-card class="pa-3">
                                                                <div class="d-flex flex-column">
                                                                    <p>
                                                                        Start Date Subscription
                                                                    </p>
                                                                    <div>
            
                                                                        <v-date-picker flat v-model="startDateSubscription" :landscape="true"
                                                                        :reactive="true">
                                                                        </v-date-picker>
            
                                                                        <slot name="footer">
                                                                            <div class="d-flex justify-end white" >
                                                                                <v-btn color="primary" plain @click="()=>{
                                                                                    isStartDateSubscription=false;
                                                                                    startDateSubscription=null;
                                                                                }">
                                                                                    Cancel
            
                                                                                </v-btn>
                                                                                <v-btn color="primary" @click="()=>{
                                                                                    isStartDateSubscription = false;
                                                                                }">
                                                                                    Confirm
                                                                                </v-btn>
                                                                            </div>
                                                                        </slot>
                                                                    </div>
                                                                </div>
                                                            </v-card>
                                                            
                                                        
                                                        </v-dialog>
                                                    </v-col>

                                                    <v-col class="px-0">

                                                        <v-text-field dense outlined clearable style="border-radius:0px"
                                                        prepend-inner-icon="mdi-calendar"
                                                        @click:prepend-inner="()=>{
                                                            isEndDateSubscription = true;
                                                        }"
                                                        @click="()=>{
                                                        isEndDateSubscription =true;
                                                        }"
                                                        label="End Date..."
                                                        v-model = "endDateSubscription">
                            
                                                        </v-text-field>
                                                       
                                                        <v-dialog v-model="isEndDateSubscription" height="auto" width="auto">
                                                            <v-card class="pa-3">
                                                            <div class="d-flex flex-column">
                                                                <p>
                                                                    End Date Subscription
                                                                </p>
                                                                <div>
                                                                    <v-date-picker flat v-model="endDateSubscription" :landscape="true"
                                                                    :reactive="true">
                                                                    </v-date-picker>
                                                                </div>
                                                            </div>
                                                            <div name="footer">
                                                                <div class="d-flex justify-end my-3">
                                                                    <v-btn color="primary" plain @click="()=>{
                                                                        isEndDateSubscription = false;
                                                                        endDateSubscription =null;
                                                                    }">
                                                                        Cancel
                                                                    </v-btn>
                                                                    <v-btn color="primary" @click="()=>{
                                                                        isEndDateSubscription = false;
                                                                    }">
                                                                        Confirm
                                                                    </v-btn>
                                                                </div>
                                                            </div>
                                                        </v-card>
                                                           
                                                        </v-dialog>
                                                    </v-col>

                                                </v-row>
                                                

                                              



                                            </v-col>
                                            <v-col class="">
                                                <div class="d-flex justify-start px=-">
                                                    <span class="text-h7 grey--text mb-3 ">
                                                        Expired Date :
                                                    </span>
                                                </div>

                                                <v-row class="mr-1">
                                                    <v-col class="px-0">
                                                        <v-text-field style="border-radius:0px" dense outlined clearable
                                                        prepend-inner-icon="mdi-calendar"
                                                        @click:prepend-inner="()=>{
                                                            isStartDateExpired = true;
                                                        }"
                                                        @click="()=>{
                                                            isStartDateExpired =true;
                                                        }"
                                                        label="Start Date..."
                                                        v-model = "startDateExpired">
                            
                                                        </v-text-field>
                            
                                                        <v-dialog v-model="isStartDateExpired" height="auto" width="auto">
                                                            <v-card class="pa-3">
                                                                <div class="d-flex flex-column">
                                                                    <p>
                                                                        Start Date Expired
                                                                    </p>
                                                                    <div>
            
                                                                        <v-date-picker flat v-model="startDateExpired" :landscape="true"
                                                                        :reactive="true">
                                                                        </v-date-picker>
            
                                                                        <slot name="footer">
                                                                            <div class="d-flex justify-end white" >
                                                                                <v-btn color="primary" plain @click="()=>{
                                                                                    isStartDateExpired=false;
                                                                                    startDateExpired=null;
                                                                                }">
                                                                                    Cancel
            
                                                                                </v-btn>
                                                                                <v-btn color="primary" @click="()=>{
                                                                                    isStartDateExpired = false;
                                                                                }">
                                                                                    Confirm
                                                                                </v-btn>
                                                                            </div>
                                                                        </slot>
                                                                    </div>
                                                                </div>
                                                            </v-card>
                                                            
                                                        
                                                        </v-dialog>
                                                    </v-col>
                                                    <v-col class="px-0">


                                                        <v-text-field dense outlined clearable style="border-radius:0px 4px 4px 0px"
                                                        prepend-inner-icon="mdi-calendar"
                                                        @click:prepend-inner="()=>{
                                                            isEndDateExpired = true;
                                                        }"
                                                        @click="()=>{
                                                        isEndDateExpired =true;
                                                        }"
                                                        label="End Date..."
                                                        v-model = "endDateExpired">
                            
                                                        </v-text-field>
                                                        
                                                        <v-dialog v-model="isEndDateExpired" height="auto" width="auto">
                                                            <v-card class="pa-3">
                                                            <div class="d-flex flex-column">
                                                                <p>
                                                                    End Date Expired
                                                                </p>
                                                                <div>
                                                                    <v-date-picker flat v-model="endDateExpired" :landscape="true"
                                                                    :reactive="true">
                                                                    </v-date-picker>
                                                                </div>
                                                            </div>
                                                            <div name="footer">
                                                                <div class="d-flex justify-end my-3">
                                                                    <v-btn color="primary" plain @click="()=>{
                                                                        isEndDateExpired = false;
                                                                        endDateExpired =null;
                                                                    }">
                                                                        Cancel
                                                                    </v-btn>
                                                                    <v-btn color="primary" @click="()=>{
                                                                        isEndDateExpired = false;
                                                                    }">
                                                                        Confirm
                                                                    </v-btn>
                                                                </div>
                                                            </div>
                                                        </v-card>
                                                            
                                                        </v-dialog>
                                                    </v-col>

                                                 </v-row>

                                            </v-col>
                                        </v-row>


                                       
                                        <div class="d-flex justify-end mr-4 ">
                                            <div class="px-2"> 
                                                <v-btn color="primary" outlined @click="resetFilter">
                                                    Reset Filter
                                                </v-btn>

                                            </div>
                                            <v-btn color="primary" @click="filterDate" >
                                                Filter
                                            </v-btn>
                                                
                                        </div>  

                                    
                                        <v-col class="mt-7 pt-7 pb-0">
                                        </v-col>
                                        <v-col>
                                            <v-autocomplete v-model="statusSelected" :items="statusOptions" dense outlined label="Status" @change="filterDate" >

                                            </v-autocomplete>
                                        </v-col>
                                        <v-col class="py-0">
                                            <v-text-field v-model="searchData" dense outlined label="Search...">
    
                                            </v-text-field>
                                        </v-col>
                                        <div class="d-flex justify-end mb-4">
                                            <!-- <v-btn color="primary" class="mx-2" @click="resetFilter" outlined>
                                                Reset Filter
                                            </v-btn> -->
                                            <JsonCSV :data="downloadData">
                                                <v-btn color="primary">
                                                    Download
                                                </v-btn>
                                            </JsonCSV>
                                        </div>
                                    </v-col>
                                </v-row>
                                
                             </v-toolbar>
                    </template>
                </v-data-table>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>

export default{
    data:()=>({
        data:null,
        filteredData:null,
        isStartDate :false,
        isStartDateSubscription:false,
        startDateSubscription:null,
        startDateExpired:null,
        isStartDateExpired:false,
        endDateeExpired:null,
        isEndDateExpired:false,
        isEndDateSubscription:false,
        endDateSubscription:null,
        searchData:null,
        downloadData:null,
        isEndDate:false,
        statusSelected:"All",
        startDate:null,
        statusOptions:["All","Active","Inactive"],
        endDate:null,
        dataHeader:[
            {
                text:'#',
                value:'Index',
                align:'start',
                sortable:false,
            },
            {
                text:'PIC',
                value:'user_name',
            },
            {
                text:'Company',
                value:'company_name',
            },
            {
                text:'Assigned Date',
                value:'created_at'
            },
            {
                text:'Subscription Date',
                value:'invoice_created_at',
            },
            {
                text:'Expired Date',
                value:'invoice_expired_at',
             
            },
            {
                text:'Status',
                value:'deleted_at',
            }
        ],
        api:
            {
                isLoading : false,
                isError:false,
                error:null,
                url:null,
                isSuccesful:false,
                success:null,
            }
    }),
    created(){
        this.api.callbackReset = () => {
                this.api.isLoading = true;
                this.api.isError = false;
            };
            this.api.callbackError = (e) => {
                this.api.isLoading = false;
                this.api.isError = true;
                this.api.error = e;
            };
            this.api.callbackSuccess = (resp) => {
              if(resp.class==="getAllPICWithCompany"){
                    this.data =resp.data;
                    this.filteredData = resp.data;
                    this.downloadData = resp.data.map(obj=>({
                        "PIC":obj.user_name,
                        "Company":obj.company_name,
                        "Assigned Date":this.convertTimeZone(obj.created_at),
                        "Subscription Date":this.convertTimeZone(obj.invoice_created_at),
                        "Expired Date":this.convertTimeZone(obj.invoice_expired_at),
                        "Status":(obj.deleted_at==null)?"Active":"Inactive",
                    }));
              }
              this.api.isLoading = false;
              this.api.isError = false
            };
    },
    mounted(){
        this.fetch();
    },
    methods:{
        fetch(){
            let fetchPICCompaniesApi = this.fetchPICCompanies();
            this.$api.fetch(fetchPICCompaniesApi);
        },
        fetchPICCompanies(){
            let tempApi = this.$_.clone(this.api);
            tempApi.method = "GET";
            tempApi.url = process.env.VUE_APP_SERVER_API+"/report/pic/company";
            return tempApi;
        },
        filterDate(){
            let tempData = this.$_.clone(this.data);


           

            let tempStartDate = this.$_.clone(this.startDate);
            let tempEndDate = this.$_.clone(this.endDate);

            if(tempStartDate!=null){

                tempData =  tempData.filter(function(item){
                    let tempCreatedAt = item.created_at.split(" ")[0];
                    if(tempCreatedAt>=tempStartDate){
                        return true;
                    }
                })
            }


            if(tempEndDate!=null){
                tempData = tempData.filter(function(item){
                    let tempCreatedAt = item.created_at.split(" ")[0];
                  
                    if(tempCreatedAt<=tempEndDate){
                        return true;
                    }
                })
            }


            let tempStartDateSubscription = this.$_.clone(this.startDateSubscription);
            let tempEndDateSubscription = this.$_.clone(this.endDateSubscription);


            if(tempStartDateSubscription!=null){

                tempData =  tempData.filter(function(item){
                    let tempCreatedAt = item.invoice_created_at.split(" ")[0];
                    if(tempCreatedAt>=tempStartDateSubscription){
                        return true;
                    }
                })
            }

            if(tempEndDateSubscription!=null){

                tempData =  tempData.filter(function(item){
                    let tempCreatedAt = item.invoice_created_at.split(" ")[0];
                    if(tempCreatedAt<=tempEndDateSubscription){
                        return true;
                    }
                })
            }


            let tempStartDateExpired = this.$_.clone(this.startDateExpired);
            let tempEndDateExpired = this.$_.clone(this.endDateExpired);


            if(tempStartDateExpired!=null){

                tempData =  tempData.filter(function(item){
                    let tempCreatedAt = item.invoice_expired_at.split(" ")[0];
                    if(tempCreatedAt>=tempStartDateExpired){
                        return true;
                    }
                })
            }

            if(tempEndDateExpired!=null){

                tempData =  tempData.filter(function(item){
                    let tempCreatedAt = item.invoice_expired_at.split(" ")[0];
                    if(tempCreatedAt<=tempEndDateExpired){
                        return true;
                    }
                })
            }


            let tempFilterStatus = this.$_.clone(this.statusSelected)

            if(tempFilterStatus!="All"){
                tempData = tempData.filter(function(item){
                    if(tempFilterStatus=='Active'){
                        if(item.deleted_at==null){
                            return true;
                        }
                    }
                    
                    if(tempFilterStatus=='Inactive'){
                        if(item.deleted_at!=null){
                            return true;
                        }
                    }
                })
            }

            this.filteredData = tempData;
            this.downloadData = this.filteredData.map(obj=>({
                        "PIC":obj.user_name,
                        "Company":obj.company_name,
                        "Assigned Date":this.convertTimeZone(obj.created_at),
                        "Subscription Date":this.convertTimeZone(obj.invoice_created_at),
                        "Expired Date":this.convertTimeZone(obj.invoice_expired_at),
                        "Status":(obj.deleted_at==null)?"Active":"Inactive",

                    }));

        },
        convertTimeZone(time){
            return this.$moment(time).format("LLL");
        },
        resetFilter(){
            this.filteredData = this.data;
            this.startDate = null;
            this.endDate = null;
            this.endDateSubscription = null;
            this.startDateSubscription = null;
            this.searchData = null;
            this.downloadData = this.filteredData.map(obj=>({
                        "PIC":obj.user_name,
                        "Company":obj.company_name,
                        "Assigned Date":this.convertTimeZone(obj.created_at),
                        "Subscription Date":this.convertTimeZone(obj.invoice_created_at),
                        "Expired Date":this.convertTimeZone(obj.invoice_expired_at),
                        "Status":(obj.deleted_at==null)?"Active":"Inactive",

                    }));
        }
    },
}
</script>